<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";


type PinLocation = "topRight" | "bottomLeft";

defineProps<{
    pinLocation: PinLocation;
    showEmail?: boolean;
}>();

const civiqaUser = useCiviqaUser();

</script>

<template>
    <Menu as="div" class="relative inline-block text-left z-50">
        <div>
            <MenuButton
                class="relative flex"
            >
                <slot />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                :class="{
                    'right-0': pinLocation === 'topRight',
                    'bottom-12': pinLocation === 'bottomLeft',
                }"
            >
                <div class="py-1">
                    <MenuItem v-slot="{ active }">
                        <div v-if="showEmail && civiqaUser?.email" class="px-4 py-2 text-sm text-gray-400">
                            {{ civiqaUser?.email }}
                        </div>
                        <div v-else>
                            
                        </div>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <NuxtLink
                            
                            href="/enviro/home"
                            class="block px-4 py-2 text-sm"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                            ]"
                        >
                            Dashboard
                        </NuxtLink>
                    </MenuItem>

                    <MenuItem v-slot="{ active }">
                        <NuxtLink
                            href="/logout"
                            class="block px-4 py-2 text-sm"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                            ]"
                        >
                            Log out
                        </NuxtLink>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
